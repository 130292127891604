import React, { useState, useEffect } from "react";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, InputAdornment, IconButton } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const Step0Medical = ({
        conversionRates,
        formDataStep0Medical,
        setFormDataStep0Medical,
        errors,
        setErrors,
        MedicalPlans,
        today,
        calculatePremium,
        fixedStartDate,
        fixedStartDateProduct
      }) => {  

        // convertor Function
        const convertAmount = (amount) => {
          // Remove commas and convert to number
          const amountclean = parseFloat(amount.replace(/,/g, ''));
          // Convert to selected currency
          return  (amountclean * conversionRates[formDataStep0Medical.currency]).toFixed(2);
          };

    // Premuim Summaries
    const [inpatientPremiumSummary, setInpatientPremiumSummary] = useState({
      premium: 0,
      itl: 0,
      pcf: 0,
      stampDuty: 0,
      total: 0
    })
    const [outpatientPremiumSummary, setOutpatientPremiumSummary] = useState({
      premium: 0,
      itl: 0,
      pcf: 0,
      stampDuty: 0,
      total: 0
    })
    const [dentalPremiumSummary, setDentalPremiumSummary] = useState({
      premium: 0,
      itl: 0,
      pcf: 0,
      stampDuty: 0,
      total: 0
    })
    const [opticalPremiumSummary, setOpticalPremiumSummary] = useState({
      premium: 0,
      itl: 0,
      pcf: 0,
      stampDuty: 0,
      total: 0
    })

    const [displayedPlans, setDisplayedPlans] = useState([])
    useEffect(() => {
      if (formDataStep0Medical.coverType === "per-person") {
        setDisplayedPlans(MedicalPlans[0]);
      } else if (formDataStep0Medical.coverType === "per-family") {
        setDisplayedPlans(MedicalPlans[1]);
      }
    }, [formDataStep0Medical.coverType, MedicalPlans])

    // Set premium summaries when component is first rendered
    useEffect(() => {
      setInpatientPremiumSummary(calculatePremium(formDataStep0Medical.selectedInpatientPlan, formDataStep0Medical.dependantCount, displayedPlans));
      setOutpatientPremiumSummary(calculatePremium(formDataStep0Medical.selectedOutpatientPlan, formDataStep0Medical.dependantCount, displayedPlans));
      setDentalPremiumSummary(calculatePremium(formDataStep0Medical.selectedDentalPlan, formDataStep0Medical.dependantCount, displayedPlans));
      setOpticalPremiumSummary(calculatePremium(formDataStep0Medical.selectedOpticalPlan, formDataStep0Medical.dependantCount, displayedPlans));
    }, [formDataStep0Medical, displayedPlans]);

    return (
      <div className="bg-white p-2 shadow-div shadow-3xl mt-1">
        <form className="mt-4">
          <hr className="my-4 bg-gray-900"   />
          <div className='mb-3'>
            
            <h5 className='font-semibold'>Medical</h5>  
          </div>
  
          {/* Cover Type */}
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 mb-2 gap-2">
            <div className="flex-1 self-end">
            
              <FormControl fullWidth variant="outlined" error={!!errors.coverType}>
                <InputLabel id="cover-type-label">Cover Type</InputLabel>
                <Select
                  labelId="cover-type-label"
                  id="cover-type"
                  label="Cover Type"
                  value={formDataStep0Medical.coverType}
                  onChange={(e) => {
                    setFormDataStep0Medical({ ...formDataStep0Medical, coverType: e.target.value });
                    if (formDataStep0Medical.selectedInpatientPlan) {
                      setFormDataStep0Medical({
                        ...formDataStep0Medical,
                        selectedInpatientPlan: null,
                        selectedOutpatientPlan: null,
                        selectedDentalPlan: null,
                        selectedOpticalPlan: null
                      })
                    }  
                    if (errors.coverType) {
                      setErrors({ ...errors, coverType: '' });
                    }
                  }}
                >
                  <MenuItem value="per-person">Per-person Cover</MenuItem>
                  <MenuItem value="per-family">Per-family Cover (Shared)</MenuItem>
                </Select>
                {errors.coverType && (
                  <FormHelperText sx={{ fontSize: '0.75rem', color: 'error.main' }}>
                    {errors.coverType}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
  
            {/* Dependant Count */}
              <div className="flex-1 self-end">
                <TextField
                  // required
                  id="dependant-count"
                  label="Dependant Count"
                  variant="outlined"
                  fullWidth
                  type="number"
                   
                  value={formDataStep0Medical.dependantCount}
                  onChange={(e) => {
                    setFormDataStep0Medical({ ...formDataStep0Medical, dependantCount: e.target.value });
                    if (formDataStep0Medical.selectedInpatientPlan) {
                      setFormDataStep0Medical({
                        ...formDataStep0Medical,
                        selectedInpatientPlan: null,
                        selectedOutpatientPlan: null,
                        selectedDentalPlan: null,
                        selectedOpticalPlan: null
                      })
                    }  
                    if (errors.dependantCount) {
                      setErrors({ ...errors, dependantCount: '' });
                    }
                  }}
                  error={!!errors.dependantCount}
                  helperText={errors.dependantCount}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                />
              </div>
          
            {/* Policy Start Date */}
            <div className="flex-1 self-end">
              <TextField
                id="policy-start-date"
                label="Policy Start Date"
                variant="outlined"
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={!!fixedStartDate && fixedStartDateProduct !== 'medical' ? fixedStartDate : formDataStep0Medical.policyStartDate}
                disabled={!!fixedStartDate && fixedStartDateProduct !== 'medical'}
                onChange={(e) => {
                  const newDate = e.target.value;
                  setFormDataStep0Medical({ ...formDataStep0Medical, policyStartDate: newDate });
                  if (errors.policyStartDate) {
                    setErrors({ ...errors, policyStartDate: '' });
                  }
                }}
                InputProps={{
                  inputProps: { min: today },
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <IconButton edge="end">
                  //       <CalendarTodayIcon />
                  //     </IconButton>
                  //   </InputAdornment>
                  // ),
                }}
                error={!!errors.policyStartDate}
                helperText={errors.policyStartDate}
                sx={{ mt: 1 }}
              />
            </div>
          </div>
  
          {/* Product Options Table */}
          {formDataStep0Medical.coverType !== '' &&
            <div className="mt-6">
            <h5 className="font-semibold mb-2">Product Options</h5>
              <div className="overflow-x-auto">
                  <div className="min-w-full border border-gray-300">
                  <div className="Table-titles grid grid-cols-4 gap-2 text-center font-semibold border-b-2 text-white border-gray-300 bg-gray-600">
                      {/* <div className="border border-gray-300 p-2 Product_ID">Product ID</div> */}
                      <div className="border border-gray-300 p-2">Plan</div>
                      {/* <div className="border border-gray-300 p-2">Benefit</div> */}
                      {/* <div className="border border-gray-300 p-2">Cover Type</div> */}
                      <div className="border border-gray-300 p-2">Cover Amount</div>
                      <div className="border border-gray-300 p-2">Premium (Base)</div>
                      <div className="border border-gray-300 p-2">Select a Plan</div>
                  </div>
                  
                  {/* Inpatient Plans */}
                  <div className="font-semibold border-t border-gray-300 bg-gray-500 text-white p-2  text-center">
                      Inpatient Plans
                  </div>
                  {displayedPlans.filter(plan => plan.benefit === 'Inpatient').map((plan) => (
                      <div
                      key={plan.product_Id}
                      className={`Table-datas grid grid-cols-4 gap-2 text-center border-b border-gray-300 ${plan.product_Id === formDataStep0Medical.selectedInpatientPlan ? 'bg-green-700 text-white' : ''}`}
                      >
                      {/* <div className="border border-gray-300 p-2 Product_ID">{plan.product_Id}</div> */}
                      <div className="border border-gray-300 p-2">{plan.plan_Name}</div>
                      {/* <div className="border border-gray-300 p-2">{plan.benefit}</div>
                      <div className="border border-gray-300 p-2">{plan.cover_Type}</div> */}
                      <div className="border border-gray-300 p-2">{convertAmount(String(plan.cover_Amount))}</div>
                      <div className="border border-gray-300 p-2">{convertAmount(String(plan.premium))}</div>
                      <div className="border border-gray-300 p-2">
                        <button
                        onClick={() => {
                          setInpatientPremiumSummary(calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans));
                          setFormDataStep0Medical({
                            ...formDataStep0Medical,
                            selectedInpatientPlan: plan.product_Id,
                            totalInpatientPremium: calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans).total
                          });
                        }}
                        type="button"
                        style={{backgroundColor:'#157EBC'}}
                        className="hover:bg-blue-600 text-white text-sm px-3 py-1 rounded"
                        >
                        Select
                        </button>
                      </div>
                      </div>
                  ))}
                  
                  {/* Other Plans */}
                  {formDataStep0Medical.selectedInpatientPlan &&
                  <div>
                    {/* Outpatient Plans */}
                    <div className="font-semibold border-t bg-gray-500 text-white p-2 text-center">
                        Outpatient Plans
                    </div>
                    {displayedPlans.filter(plan => plan.benefit === 'Outpatient').map((plan) => (
                        <div
                        key={plan.product_Id}
                        className={`Table-datas grid grid-cols-4 gap-2 text-center border-b border-gray-300 ${plan.product_Id === formDataStep0Medical.selectedOutpatientPlan ? 'bg-green-700 text-white' : ''}`}
                        >
                        {/* <div className="border border-gray-300 p-2 Product_ID">{plan.product_Id}</div> */}
                        <div className="border border-gray-300 p-2">{plan.plan_Name}</div>
                        {/* <div className="border border-gray-300 p-2">{plan.benefit}</div>
                        <div className="border border-gray-300 p-2">{plan.cover_Type}</div> */}
                        <div className="border border-gray-300 p-2">{convertAmount(String(plan.cover_Amount))}</div>
                        <div className="border border-gray-300 p-2">{convertAmount(String(plan.premium))}</div>
                        <div className="border border-gray-300 p-2">
                            <button
                            onClick={() => {
                              setOutpatientPremiumSummary(calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans));
                              setFormDataStep0Medical({
                                ...formDataStep0Medical,
                                selectedOutpatientPlan: plan.product_Id,
                                totalOutpatientPremium: calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans).total
                              });
                            }}
                            type="button"
                            className="bg-blue-500 hover:bg-blue-600 text-white text-sm px-3 py-1 rounded"
                            >
                            Select
                            </button>
                        </div>
                        </div>
                    ))}
                    
                    {/* Dental Plans */}
                    <div className="font-semibold border-t bg-gray-500 text-white p-2 p-2 text-center">
                        Dental Plans
                    </div>
                    {displayedPlans.filter(plan => plan.benefit === 'Dental').map((plan) => (
                        <div
                        key={plan.product_Id}
                        className={`Table-datas grid grid-cols-4 gap-2 text-center border-b border-gray-300 ${plan.product_Id === formDataStep0Medical.selectedDentalPlan ? 'bg-green-700 text-white' : ''}`}
                        >
                        {/* <div className="border border-gray-300 p-2 Product_ID">{plan.product_Id}</div> */}
                        <div className="border border-gray-300 p-2">{plan.plan_Name}</div>
                        {/* <div className="border border-gray-300 p-2">{plan.benefit}</div>
                        <div className="border border-gray-300 p-2">{plan.cover_Type}</div> */}
                        <div className="border border-gray-300 p-2">{convertAmount(String(plan.cover_Amount))}</div>
                        <div className="border border-gray-300 p-2">{convertAmount(String(plan.premium))}</div>
                        <div className="border border-gray-300 p-2">
                            <button
                            onClick={() => {
                              setDentalPremiumSummary(calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans));
                              setFormDataStep0Medical({
                                ...formDataStep0Medical,
                                selectedDentalPlan: plan.product_Id,
                                totalDentalPremium: calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans).total
                              });
                            }}
                            type="button"
                            className="bg-blue-500 hover:bg-blue-600 text-white text-sm px-3 py-1 rounded"
                            >
                            Select
                            </button>
                        </div>
                        </div>
                    ))}
                    
                    {/* Optical Plans */}
                    <div className="font-semibold border-t bg-gray-500 text-white p-2   text-center">
                        Optical Plans
                    </div>
                    {displayedPlans.filter(plan => plan.benefit === 'Optical').map((plan) => (
                        <div
                        key={plan.product_Id}
                        className={`Table-datas grid grid-cols-4 gap-2 text-center border-b border-gray-300 ${plan.product_Id === formDataStep0Medical.selectedOpticalPlan ? 'bg-green-700 text-white' : ''}`}
                        >
                        {/* <div className="border border-gray-300 p-2 Product_ID">{plan.product_Id}</div> */}
                        <div className="border border-gray-300 p-2">{plan.plan_Name}</div>
                        {/* <div className="border border-gray-300 p-2">{plan.benefit}</div>
                        <div className="border border-gray-300 p-2">{plan.cover_Type}</div> */}
                        <div className="border border-gray-300 p-2">{convertAmount(String(plan.cover_Amount))}</div>
                        <div className="border border-gray-300 p-2">{convertAmount(String(plan.premium))}</div>
                        <div className="border border-gray-300 p-2">
                            <button
                            onClick={() => {
                              setOpticalPremiumSummary(calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans));
                              setFormDataStep0Medical({
                                ...formDataStep0Medical,
                                selectedOpticalPlan: plan.product_Id,
                                totalOpticalPremium: calculatePremium(plan.product_Id, formDataStep0Medical.dependantCount, displayedPlans).total
                              });
                            }}
                            type="button"
                            style={{backgroundColor:'#157EBC'}}
                            className="  hover:bg-blue-600 text-white text-sm px-3 py-1 rounded"
                            >
                            Select
                            </button>
                        </div>
                        </div>
                    ))}
                    </div>
                    }
                  </div>
                </div>
              {errors.selectedPlan && (
                  <p className="text-red-500 text-sm mt-2">{errors.selectedPlan}</p>
              )}
            </div>
          }

  
          {/* Container for two-column layout on large screens */}
          <div className="mt-6 flex flex-col lg:flex-row lg:gap-6">
            {/* Product Summary */}
            {formDataStep0Medical.selectedInpatientPlan && (
                <div className="flex flex-col p-4 border rounded-lg shadow-md bg-white lg:w-1/2">
                    <div className="border-b border-gray-300 pb-2 mb-2">
                    <h2 className="text-xl font-semibold mb-4">Premium Summary</h2>
                    </div>
                    <div className="flex flex-col gap-4 text-sm text-gray-700">
                    {/* Display summary based on selected plan and benefit */}
                      <div>
                      <h3 className="text-base font-medium mb-4">Inpatient Premium Summary</h3>
                      <div className="flex flex-col gap-2 text-sm text-gray-700 border-t">
                          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Premium</span>
                          <span>{inpatientPremiumSummary.premium}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">ITL</span>
                          <span>{inpatientPremiumSummary.itl || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">PCF</span>
                          <span>{inpatientPremiumSummary.pcf || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Stamp Duty</span>
                          <span>{inpatientPremiumSummary.stampDuty || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between">
                          <span className="font-medium">TOTAL</span>
                          <span><b>{inpatientPremiumSummary.total || 'N/A'}</b></span>
                          </div>
                      </div>
                      </div>

                      {displayedPlans.filter(plan => plan.product_Id === formDataStep0Medical.selectedOutpatientPlan).map(plan => (
                        <div key={plan.product_Id}>
                        <h3 className="text-base font-medium mb-4">{plan.benefit} Premium Summary</h3>
                        <div className="flex flex-col gap-2 text-sm text-gray-700 border-t">
                          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Premium</span>
                          <span>{outpatientPremiumSummary.premium}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">ITL</span>
                          <span>{outpatientPremiumSummary.itl || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">PCF</span>
                          <span>{outpatientPremiumSummary.pcf || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Stamp Duty</span>
                          <span>{outpatientPremiumSummary.stampDuty || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between">
                          <span className="font-medium">TOTAL</span>
                          <span><b>{outpatientPremiumSummary.total || 'N/A'}</b></span>
                          </div>
                        </div>
                        </div>
                      ))}
                      {displayedPlans.filter(plan => plan.product_Id === formDataStep0Medical.selectedDentalPlan).map(plan => (
                        <div key={plan.product_Id}>
                        <h3 className="text-base font-medium mb-4">{plan.benefit} Premium Summary</h3>
                        <div className="flex flex-col gap-2 text-sm text-gray-700 border-t">
                          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Premium</span>
                          <span>{dentalPremiumSummary.premium}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">ITL</span>
                          <span>{dentalPremiumSummary.itl || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">PCF</span>
                          <span>{dentalPremiumSummary.pcf || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Stamp Duty</span>
                          <span>{dentalPremiumSummary.stampDuty || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between">
                          <span className="font-medium">TOTAL</span>
                          <span><b>{dentalPremiumSummary.total || 'N/A'}</b></span>
                          </div>
                        </div>
                        </div>
                      ))}
                      {displayedPlans.filter(plan => plan.product_Id === formDataStep0Medical.selectedOpticalPlan).map(plan => (
                        <div key={plan.product_Id}>
                        <h3 className="text-base font-medium mb-4">{plan.benefit} Premium Summary</h3>
                        <div className="flex flex-col gap-2 text-sm text-gray-700 border-t">
                          <div className="flex justify-between border-b pt-2 border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Premium</span>
                          <span>{opticalPremiumSummary.premium}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">ITL</span>
                          <span>{opticalPremiumSummary.itl || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">PCF</span>
                          <span>{opticalPremiumSummary.pcf || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between border-b border-gray-300 pb-2 mb-2">
                          <span className="font-medium">Stamp Duty</span>
                          <span>{opticalPremiumSummary.stampDuty || 'N/A'}</span>
                          </div>
                          <div className="flex justify-between">
                          <span className="font-medium">TOTAL</span>
                          <span><b>{opticalPremiumSummary.total || 'N/A'}</b></span>
                          </div>
                        </div>
                        </div>
                      ))}
                    </div>
                </div>
                )}

  
            {/* Disclaimer */}
            <div className={`lg:w-1/2 ${formDataStep0Medical.selectedInpatientPlan ? 'lg:pl-6' : ''}`}>
              <p className="text-sm text-gray-600 mt-6 lg:mt-0">
                <b>DISCLAIMER:</b> The premium shown is approximate and should be used for illustrative and general information purposes only. The final premium amount may change and will be determined accurately after further assessment.
              </p>
            </div>
          </div>        
        </form>
      </div>
    );
  };

 export default Step0Medical;