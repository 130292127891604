import React, { useState, useEffect } from "react";

const Step3PolicySummary = ({
  selectedPlans,
  formDataStep0EvacuationRepatriation,
  formDataStep0LastExpense,
  formDataStep0Medical,
  formDataStep0HospitalCash,
  formDataStep0PersonalAccident,
  calculatePremium,
  conversionRates,
  contactAndLoginsAndCurrency,
  loadMultiplier
}) => {
  // convertor Function
  const convertAmount = (amount) => {
    // Convert to selected currency
    return (amount * conversionRates[contactAndLoginsAndCurrency.currency]).toFixed(2);
  };  
  
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Get day with leading zero
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month with leading zero (months are 0-based)
    const year = date.getFullYear(); // Get full year
    return `${month}/${day}/${year}`;
  }

  const addCoverPeriods = (productData) => {
    let startDate = new Date(productData.policyStartDate);
    console.log(productData.policyStartDate)
    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 364);
    startDate = formatDate(startDate);
    endDate = formatDate(endDate);
    return `${startDate} to ${endDate}`
  }

  const [localSelectedPlans, setLocalSelectedPlans] = useState(selectedPlans);
  const [coverPeriodsAdded, setCoverPeriodsAdded] = useState(false);

  useEffect(() => {
    setCoverPeriodsAdded(true);

    const addCoverPeriodsAndPremium = () => {
      // Prepare the new state
      const updatedPlans = localSelectedPlans.map(plan => {
        if (plan.product_No === 1) {
          console.log(formDataStep0EvacuationRepatriation);
          return {
            ...plan,
            coverPeriod: addCoverPeriods(formDataStep0EvacuationRepatriation),
            premiumData: calculatePremium(plan.product_Id, formDataStep0EvacuationRepatriation.dependantCount, selectedPlans)
          };
        }
        if (plan.product_No === 2) {
          return {
            ...plan,
            coverPeriod: addCoverPeriods(formDataStep0LastExpense),
            premiumData: calculatePremium(plan.product_Id, formDataStep0LastExpense.dependantCount, selectedPlans)
          };
        }
        if (plan.product_No === 3) {
          console.log(calculatePremium(plan.inpatient_Id, formDataStep0Medical.dependantCount, selectedPlans));
          console.log(plan.inpatient_Id);
          return {
            ...plan,
            coverPeriod: addCoverPeriods(formDataStep0Medical),
            premiumData_Inpatient: plan.inpatient_Id ? plan.inpatient_premium : null,
            premiumData_Outpatient: plan.outpatient_Id ? plan.outpatient_premium : null,
            premiumData_Dental: plan.dental_Id ? plan.dental_premium : null,
            premiumData_Optical: plan.optical_Id ? plan.optical_premium : null
          };
        }
        if (plan.product_No === 4) {
          return {
            ...plan,
            coverPeriod: addCoverPeriods(formDataStep0HospitalCash),
            premiumData: calculatePremium(plan.product_Id, formDataStep0HospitalCash.dependantCount, selectedPlans)
          };
        }
        if (plan.product_No === 5) {
          return {
            ...plan,
            coverPeriod: addCoverPeriods(formDataStep0PersonalAccident),
            premiumData: calculatePremium(plan.product_Id, formDataStep0PersonalAccident.dependantCount, selectedPlans)
          };
        }
        return plan;
      });
      
      // Update the state with the new plans one time
      if (!coverPeriodsAdded) {
        setLocalSelectedPlans(updatedPlans);
        setCoverPeriodsAdded(true)
      }
    };
  
    addCoverPeriodsAndPremium();
  }, [
      localSelectedPlans,
      formDataStep0EvacuationRepatriation,
      formDataStep0LastExpense,
      formDataStep0Medical,
      formDataStep0HospitalCash,
      formDataStep0PersonalAccident
  ]);

  return (
    <div>
      {localSelectedPlans.map((plan, key) => (
        <div key={key} className="bg-white p-2 shadow-div shadow-3xl mt-1">
          <h5 className='font-semibold'>We refer to your application for {plan.cover_Type || 'N/A'} {plan.product} micro insurance. Below is the policy summary: </h5>
          
          <div>
            <div className="my-5 grid grid-cols-2">
              <label>Product</label>
              <p>{plan.product}</p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Cover Period</label>
              <p>{plan.coverPeriod}</p>
            </div>

            {plan.product !== 'Medical' && 
            <div className="my-5 grid grid-cols-2">
              <label>Benefit Limits</label>
              <p>{contactAndLoginsAndCurrency.currency + ' ' + convertAmount(plan.cover_Amount)}</p>
            </div>
            }
            {plan.product === 'Medical' && 
            <div className="my-5 grid grid-cols-2">
              <label>Benefit Limits</label>
              <div>
                <p>Inpatient: {convertAmount(plan.inpatient_cover_Amount)}</p>
                {plan.outpatient_cover_Amount && <p>Outpatient: {convertAmount(plan.outpatient_cover_Amount)}</p>}
                {plan.dental_cover_Amount && <p>Dental: {convertAmount(plan.dental_cover_Amount)}</p>}
                {plan.optical_cover_Amount && <p>Optical: {convertAmount(plan.optical_cover_Amount)}</p>}
              </div>
            </div>
            }

            <div className="my-5 grid grid-cols-2">
              <label>Summary of Exclusions</label>
              <p>
                1. Outpatient treatment except dental and optical if purchased.
                    <br />
                2. Extreme sports, hazardous activities, or races.
                    <br />
                3. Genetic disorders, genetic testing, and related conditions.
                    <br />
                4. Navel, Military or air force, injuries or war, civil commotion, riots, and strikes.
                    <br />
                5. Alternative treatments like herbal and acupuncture.
                    <br />
                6. Intoxication, drunkenness, intentional self-injury, suicide or attempted suicide.
                    <br />
                Please refer to the policy documents for a complete list of exclusions.
              </p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Waiting Periods</label>
              <p>General waiting period of 30 days and 1 year waiting period for Maternity, Congenital, Prematurity, Pre - existing and/or chronic conditions, HIV/AIDS and related conditions.</p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Your Obligations</label>
              <p>Take reasonable care to answer all questions carefully and accurately and disclose all material facts as not doing so could mean that the policy is invalid and all or part of a claim may not be paid. Make sure you check that all the information on your Policy Document is correct. Contact us if anything needs to be changed.</p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Policy Renewal</label>
              <p>Policy renewal is subject to satisfactory claims performance. We will email or write to you before the renewal date to confirm the premium required for the next year’s cover conditions.</p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Claiming Procedure</label>
              <p>Prior approval must be sought before accessing treatment for all inpatient treatment, day cases, dental and optical benefits. Please refer to the policy document for more details.</p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Complaints</label>
              <p>
                Complaints can be communicated through the following contacts:
                    <br />
                Principal Officer
                    <br />
                Birdview Micro Insurance
                    <br />
                P.O.Box 45157 - 00200 City Square, Nairobi, Kenya.<br />
                Email: customerservice@birdviewmicroinsurance.com
              </p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Policy Document</label>
              <p>A policy document shall be sent to your preferred address/email within 3 working days of cover commencement.</p>
            </div>

            <div className="my-5 grid grid-cols-2">
              <label>Policy Renewal</label>
              <p>Policy renewal is subject to satisfactory claims performance. We will email or write to you before the renewal date to confirm the premium required for the next year’s cover.conditions.</p>
            </div>
          </div>

          <p>You are required to pay the full premium as shown in the table below within 7 days of the date of this policy summary. Payment of premium signifies acceptance of the terms and conditions, and cover will commence on receipt of full premium. </p>

          {plan.product !== 'Medical' &&
          <div className="grid grid-cols-6">
            <div className="my-5 grid grid-rows-2">
              <label>Premium</label>
              <p>{plan.premiumData?.premium || 'N/A'}</p>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>ITL</label>
              <p>{plan.premiumData?.itl || 'N/A'}</p>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>IPCF</label>
              <p>{plan.premiumData?.pcf || 'N/A'}</p>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>Stamp Duty</label>
              <p>{plan.premiumData?.stampDuty || 'N/A'}</p>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>Total</label>
              <p>{parseFloat(plan.premiumData?.total) || 'N/A'}</p>
            </div>
          </div>
          }

          {plan.product === 'Medical' &&
          <div className="grid grid-cols-6">
            <div className="my-5 grid grid-rows-2">
              <label>Benefit</label>
              <div>                
                <p>Inpatient</p>
                {plan.premiumData_Outpatient && <p>Outpatient</p>}
                {plan.premiumData_Dental && <p>Dental</p>}
                {plan.premiumData_Optical && <p>Optical</p>}
              </div>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>Premium</label>
              <div>                
                {plan.premiumData_Inpatient && <p>{(plan.premiumData_Inpatient?.premium * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Outpatient && <p>{(plan.premiumData_Outpatient?.premium * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Dental && <p>{plan.premiumData_Dental?.premium || 'N/A'}</p>}
                {plan.premiumData_Optical && <p>{plan.premiumData_Optical?.premium || 'N/A'}</p>}
              </div>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>ITL</label>
              <div>
                {plan.premiumData_Inpatient && <p>{(plan.premiumData_Inpatient?.itl  * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Outpatient && <p>{(plan.premiumData_Outpatient?.itl * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Dental && <p>{plan.premiumData_Dental?.itl || 'N/A'}</p>}
                {plan.premiumData_Optical && <p>{plan.premiumData_Optical?.itl || 'N/A'}</p>}
              </div>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>IPCF</label>
              <div>                
                {plan.premiumData_Inpatient && <p>{(plan.premiumData_Inpatient?.pcf  * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Outpatient && <p>{(plan.premiumData_Outpatient?.pcf * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Dental && <p>{plan.premiumData_Dental?.pcf || 'N/A'}</p>}
                {plan.premiumData_Optical && <p>{plan.premiumData_Optical?.pcf || 'N/A'}</p>}
              </div>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>Stamp Duty</label>
              <div>                
                {plan.premiumData_Inpatient && <p>{plan.premiumData_Inpatient?.stampDuty || 'N/A'}</p>}
                {plan.premiumData_Outpatient && <p>{plan.premiumData_Outpatient?.stampDuty || 'N/A'}</p>}
                {plan.premiumData_Dental && <p>{plan.premiumData_Dental?.stampDuty || 'N/A'}</p>}
                {plan.premiumData_Optical && <p>{plan.premiumData_Optical?.stampDuty || 'N/A'}</p>}
              </div>
            </div>

            <div className="my-5 grid grid-rows-2">
              <label>Total</label>
              <div>                
                {plan.premiumData_Inpatient && <p>{(plan.premiumData_Inpatient?.total  * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Outpatient && <p>{(plan.premiumData_Outpatient?.total * loadMultiplier).toFixed(4) || 'N/A'}</p>}
                {plan.premiumData_Dental && <p>{plan.premiumData_Dental?.total || 'N/A'}</p>}
                {plan.premiumData_Optical && <p>{plan.premiumData_Optical?.total || 'N/A'}</p>}
              </div>
            </div>
          </div>
          }
        </div>
      ))}

    </div>
  )
}

export default Step3PolicySummary;
